.flex-header{
  width: 100%;
  margin-bottom: 20px;
  @media screen and (min-width: 551px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 741px) {
    margin-bottom: 10vh;
  }
  @media screen and (min-width: $container-max-width + 1px) {
    margin-bottom: 15vh;
  }
  &__top{
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
    @media screen and (min-width: 551px) {
      @include grid-width(11, 75px);
      margin-left: auto;
      margin-right: 0;
    }
    @media screen and (min-width: 741px){
      position: relative;
      border-left: 2px solid $color-gray--light;
      border-bottom: 2px solid $color-gray--light;
    }
    @media screen and (min-width: 951px){
      @include grid-width(10, 50px);
    }
    ul{
      width: 100%;
      padding: 25px 20px;
      background-color: $color-primary;
      color: $color-white;
      font-weight: 600;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-size: 0;
      @media screen and (min-width: 551px) {
        padding: 15px 20px;
        width: auto;
        float: right;
        display: block;
        font-size: 1rem;
      }
      @media screen and (min-width: 951px){
        padding: 20px 40px;
        font-size: 1.563rem;
      }
      @media screen and (min-width: $container-max-width + 1px){
        padding: 40px 60px;
      }
      li {
        position: relative;
        padding-left: 30px;
        @media screen and (min-width: 951px) {
          padding-left: 40px;
        }
        svg{
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @media screen and (min-width: 951px){
            width: 25px;
            height: 25px;
          }
          *{
            fill: currentColor;
          }
        }
        + li {
          margin-left: 20px;
          @media screen and (min-width: 551px) {
            margin-top: 10px;
            margin-left: 0;
          }
          @media screen and (min-width: 951px) {
            margin-top: 20px;
          }
        }
        a:hover{
          color: $color-black;
        }
      }
    }
    &:before{
      position: absolute;
      top: 100%;
      right: 0;
      width: 2px;
      height: 100vh;
      background-color: $color-gray--light;
      z-index: -1;
      @media screen and (min-width: 741px){
        content: '';
      }
    }
    &:after{
      position: absolute;
      bottom: 0;
      left: 100%;
      width: 25px;
      height: 2px;
      background-color: $color-gray--light;
      z-index: 1;
      @media screen and (min-width: 741px){
        content: '';
      }
      @media screen and (min-width: $container-max-width + 1px) {
        width: calc((100vw - #{$container-max-width - 40px}) / 2);
      }
    }
  }
  &__bottom{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
    @media screen and (min-width: 551px) {
      padding-top: 20px;
    }
    @media screen and (min-width: 741px) {
      padding-top: 0;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      color: $color-primary;
      font-weight: 700;
      padding-right: 20px;
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
      width: calc(100% - 155px);
      font-size: 0.865rem;
      @media screen and (min-width: 351px) {
        font-size: 1rem;
      }
      @media screen and (min-width: 401px) {
        width: calc(100% - 215px);
      }
      @media screen and (min-width: 551px){
        text-align: right;
        position: relative;
        top: 0;
        flex-wrap: nowrap;
        justify-content: flex-end;
        width: auto;
      }
      @media screen and (min-width: 951px){
        font-size: 20px;
        padding-right: 10px;
      }
      li {
        width: 100%;
        @media screen and (min-width: 551px){
          width: auto;
        }
        + li {
          margin-top: 5px;
          @media screen and (min-width: 551px) {
            margin-top: 0;
          }
          &:before {
            @media screen and (min-width: 551px) {
              content: '|';
              padding: 0 5px;
            }
            @media screen and (min-width: 951px) {
              padding: 0 10px;
            }
            @media screen and (min-width: $container-max-width + 1px) {
              padding: 0 30px;
            }
          }
        }
      }
    }
  }
}
.logo-header{
  position: absolute;
  top: 10px;
  left: 20px;
  @media screen and (min-width: 551px){
    top: 15px;
    max-height: 50px;
    background-color: $color-white;
  }
  @media screen and (min-width: 741px){
    position: relative;
    top: 0;
    left: 0;
    margin-top: -10px;
  }
  @media screen and (min-width: 951px){
    margin-top: -14px;
    max-height: 70px;
  }
  @media screen and (min-width: $container-max-width + 1px) {
    max-height: 80px;
    margin-top: -16px;
  }
  &:before{
    position: absolute;
    top: 100%;
    right: 64px;
    width: 2px;
    height: 10vh;
    background-color: $color-gray--light;
    z-index: -1;
    @media screen and (min-width: 741px){
      content: '';
    }
    @media screen and (min-width: 951px){
      right: 84px;
    }
    @media screen and (min-width: $container-max-width + 1px) {
      right: 104px;
      height: 15vh;
    }
  }
  &:after{
    position: absolute;
    bottom: 0;
    right: 75px;
    width: 100%;
    height: 2px;
    background-color: $color-gray--light;
    z-index: 1;
    @media screen and (min-width: 741px){
      content: '';
    }
    @media screen and (min-width: 951px){
      right: 100px;
    }
    @media screen and (min-width: $container-max-width + 1px) {
      width: calc((100vw - #{$container-max-width}) / 2 + 205px);
      right: 121px;
    }
  }
  svg{
    width: 120px;
    height: 32px;
    @media screen and (min-width: 551px) {
      width: 190px;
      height: 50px;
    }
    @media screen and (min-width: 951px){
      width: 250px;
      height: 70px;
    }
    @media screen and (min-width: $container-max-width + 1px) {
      width: 305px;
      height: 80px;
    }
    *{
      @media screen and (max-width: 550px){
        fill: $color-white
      }
    }
  }
}