.u-clearfix:after {
  display: table;
  clear: both;

  content: ' ';
}

.u-table {
  display: table;
}

.u-tr {
  display: table-row;
}

.u-td {
  display: table-cell;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-content-row {
  position: relative;

  width: 100%;
}

.u-content-block {
  position: relative;

  display: flex;

  width: $container-width;
  max-width: $container-max-width;
  margin: auto;
  padding: 0 $container-side-padding;

  flex-wrap: wrap;
  justify-content: space-between;
}

.u-img-container {
  position: relative;

  background: url('../img/no-image.svg') no-repeat center center / cover;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    margin: auto;

    pointer-events: none;

    object-fit: cover;
  }
  img[src=''] {
    display: none;
  }
}
