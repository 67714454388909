.row-landing{
  .u-content-block{
    @media screen and (min-width: 551px) {
      align-items: flex-end;
      align-content: flex-end;
    }
  }
  &__img{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
    @media screen and (min-width: 551px) {
      @include grid-width(4, $grid-gutter / 2);
      flex-direction: column;
    }
    @media screen and (min-width: 741px) {
      @include grid-width(3, $grid-gutter / 2);
    }
    .u-img-container{
      width: 100%;
      height: 130px;
      max-width: 120px;

      @media screen and (min-width: 401px) {
        height: 230px;
        max-width: 230px;
      }
      @media screen and (min-width: 551px) {
        margin-bottom: 10px;
      }
      &:before{
        position: absolute;
        top: 100%;
        left: 0;
        width: 2px;
        height: 50vh;
        background-color: $color-gray--light;
        z-index: -1;
        @media screen and (min-width: 741px){
          content: '';
        }
      }
      &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-gray--light, 0.5);

      }
    }
    ul{
      color: $color-secondary;
      font-weight: 700;
      max-width: 230px;
      width: 100%;
      padding-left: 15px;
      @media screen and (min-width: 551px){
        min-height: 40px;
      }
      @media screen and (max-width: 740px) and (min-width: 551px) {
        padding-left: 0;
      }
      li:last-child{
        font-size: 12px;
      }
    }
  }
  article{
    width: 100%;
    font-weight: 500;
    padding-top: 20px;
    @media screen and (min-width: 551px) {
      @include grid-width(8, $grid-gutter / 2);
      padding-bottom: 50px;
      padding-top: 0;
    }
    @media screen and (min-width: 741px) {
      margin: 0 auto;
    }
  }
}