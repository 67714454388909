/* article default styling */
h1 {
  font-size: 2em;
  line-height: 1.25;
  @media screen and (min-width: 771px) {
    font-size: 2.5em;
    line-height: 1.125;
  }
  @media screen and (min-width: 1024px) {
    font-size: 3em;
    line-height: 1.05;
  }
}
h2 {
  font-size: 1.625em;
  line-height: 1.15;
  @media screen and (min-width: 771px) {
    font-size: 2em;
    line-height: 1.25;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.25em;
  }
}
h3 {
  font-size: 1.375em;
  line-height: 1.136;
  @media screen and (min-width: 771px) {
    font-size: 1.5em;
    line-height: 1.25;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.75em;
  }
}
h4 {
  font-size: 1.125em;
  line-height: 1.11;
  @media screen and (min-width: 771px) {
    font-size: 1.125em;
    line-height: 1.122;
  }
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: .85em;
}

article {
  p,
  ol,
  ul {
    font-size: 1em;

    display: block;

    margin: 0 0 1.25em 0;
    a:not(.c-btn) {
      color: $color-primary;
      &:hover {
        color: lighten($color-primary, 10%);
      }
    }
  }
  ul {
    padding-left: 20px;

    list-style: disc;
  }
  ol {
    padding-left: 20px;

    list-style: decimal;
  }
  b,
  strong {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  .c-btn {
    margin: 0 0 1em 0;
    line-height: 1;
  }
  q,
  blockquote {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 20px;
    margin: 0 0 1em 0;
    font-size: 1.25em;
    line-height: 1.25;
    @media screen and (min-width: 771px) {
      font-size: 1.5em;
      line-height: 1.45;
    }
    p {
      margin-bottom: 0;
      &:before {
        content: '\201d';
      }
      &:after {
        content: '\201c';
      }
      + p {
        text-align: right;
        font-weight: bold;
        display: block;
        font-size: 1em;
        @media screen and (min-width: 771px) {
          font-size: 1.25em;
        }
        &:before,
        &:after {
          content: none;
        }
      }
    }
  }
  h1 {
    font-weight: bold;

    display: block;

    margin: 0 0 .67em 0;
  }
  h2 {
    font-weight: bold;

    display: block;

    margin: 0 0 .83em 0;
  }
  h3 {
    font-weight: bold;

    display: block;

    margin: 0 0 1em 0;
  }
  h4 {
    font-weight: bold;

    display: block;

    margin: 0 0 1.33em 0;
  }
  h5 {
    font-weight: bold;

    display: block;

    margin: 0 0 1.37em 0;
  }
  h6 {
    font-weight: bold;

    display: block;

    margin: 0 0 1.75em 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  mark{
    background-color: lighten($color-primary, 10%);
  }
}

[class*="row-text"] + [class*="row-text"] {
  padding-top: 0;
}