.c-btn {
  font-size: 1rem;
  font-weight: 400;

  display: table;

  padding: 11px 20px;

  cursor: pointer;
  transition: all .25s ease;
  text-align: center;
  text-decoration: none;

  color: #fff;
  border: none;
  border-radius: 10px;
  outline: none;

  appearance: none;

  &--primary {
    background-color: $color-primary;

    @extend .c-btn;
    &:hover {
      background-color: lighten($color-primary, 10%);
    }
  }
  &--secondary {
    background-color: $color-secondary;

    @extend .c-btn;
    &:hover {
      background-color: lighten($color-secondary, 10%);
    }
  }
}
