html {
  font-family: $font-primary;
  font-size: 16px;
}

body {
  min-width: 320px;
  max-width: 100vw;
  overflow-x: hidden;
  @media screen and (min-width: 551px){
    max-height: 100vh;
    overflow: hidden;
  }
}

a {
  transition: all .25s ease;
  text-decoration: none;

  color: inherit;
}

