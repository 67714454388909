.u-grid {
  position: relative;
  display: flex;
  width: calc(100% + 20px);
  margin: 0 -10px;
  flex-wrap: wrap;
  justify-content: flex-start; }

.u-grid-1 {
  width: calc(100% / 12 * 1 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-2 {
  width: calc(100% / 12 * 2 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-3 {
  width: calc(100% / 12 * 3 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-4 {
  width: calc(100% / 12 * 4 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-5 {
  width: calc(100% / 12 * 5 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-6 {
  width: calc(100% / 12 * 6 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-7 {
  width: calc(100% / 12 * 7 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-8 {
  width: calc(100% / 12 * 8 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-9 {
  width: calc(100% / 12 * 9 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-10 {
  width: calc(100% / 12 * 10 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-11 {
  width: calc(100% / 12 * 11 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

.u-grid-12 {
  width: calc(100% / 12 * 12 - 20px);
  margin-left: 10px;
  margin-right: 10px; }

@media screen and (min-width: 351px) {
  .u-grid-xs-1 {
    width: calc(100% / 12 * 1 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-2 {
    width: calc(100% / 12 * 2 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-3 {
    width: calc(100% / 12 * 3 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-4 {
    width: calc(100% / 12 * 4 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-5 {
    width: calc(100% / 12 * 5 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-6 {
    width: calc(100% / 12 * 6 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-7 {
    width: calc(100% / 12 * 7 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-8 {
    width: calc(100% / 12 * 8 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-9 {
    width: calc(100% / 12 * 9 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-10 {
    width: calc(100% / 12 * 10 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-11 {
    width: calc(100% / 12 * 11 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xs-12 {
    width: calc(100% / 12 * 12 - 20px);
    margin-left: 10px;
    margin-right: 10px; } }

@media screen and (min-width: 551px) {
  .u-grid-sm-1 {
    width: calc(100% / 12 * 1 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-2 {
    width: calc(100% / 12 * 2 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-3 {
    width: calc(100% / 12 * 3 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-4 {
    width: calc(100% / 12 * 4 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-5 {
    width: calc(100% / 12 * 5 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-6 {
    width: calc(100% / 12 * 6 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-7 {
    width: calc(100% / 12 * 7 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-8 {
    width: calc(100% / 12 * 8 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-9 {
    width: calc(100% / 12 * 9 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-10 {
    width: calc(100% / 12 * 10 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-11 {
    width: calc(100% / 12 * 11 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-sm-12 {
    width: calc(100% / 12 * 12 - 20px);
    margin-left: 10px;
    margin-right: 10px; } }

@media screen and (min-width: 771px) {
  .u-grid-md-1 {
    width: calc(100% / 12 * 1 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-2 {
    width: calc(100% / 12 * 2 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-3 {
    width: calc(100% / 12 * 3 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-4 {
    width: calc(100% / 12 * 4 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-5 {
    width: calc(100% / 12 * 5 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-6 {
    width: calc(100% / 12 * 6 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-7 {
    width: calc(100% / 12 * 7 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-8 {
    width: calc(100% / 12 * 8 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-9 {
    width: calc(100% / 12 * 9 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-10 {
    width: calc(100% / 12 * 10 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-11 {
    width: calc(100% / 12 * 11 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-md-12 {
    width: calc(100% / 12 * 12 - 20px);
    margin-left: 10px;
    margin-right: 10px; } }

@media screen and (min-width: 1181px) {
  .u-grid-xl-1 {
    width: calc(100% / 12 * 1 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-2 {
    width: calc(100% / 12 * 2 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-3 {
    width: calc(100% / 12 * 3 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-4 {
    width: calc(100% / 12 * 4 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-5 {
    width: calc(100% / 12 * 5 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-6 {
    width: calc(100% / 12 * 6 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-7 {
    width: calc(100% / 12 * 7 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-8 {
    width: calc(100% / 12 * 8 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-9 {
    width: calc(100% / 12 * 9 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-10 {
    width: calc(100% / 12 * 10 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-11 {
    width: calc(100% / 12 * 11 - 20px);
    margin-left: 10px;
    margin-right: 10px; }
  .u-grid-xl-12 {
    width: calc(100% / 12 * 12 - 20px);
    margin-left: 10px;
    margin-right: 10px; } }

.u-clearfix:after {
  display: table;
  clear: both;
  content: ' '; }

.u-table {
  display: table; }

.u-tr {
  display: table-row; }

.u-td {
  display: table-cell; }

.u-text-left {
  text-align: left; }

.u-text-center {
  text-align: center; }

.u-text-right {
  text-align: right; }

.u-content-row {
  position: relative;
  width: 100%; }

.u-content-block {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1220px;
  margin: auto;
  padding: 0 20px;
  flex-wrap: wrap;
  justify-content: space-between; }

.u-img-container {
  position: relative;
  background: url("../img/no-image.svg") no-repeat center center/cover; }
  .u-img-container img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    pointer-events: none;
    object-fit: cover; }
  .u-img-container img[src=''] {
    display: none; }

.c-btn, .c-btn--primary, .c-btn--secondary {
  font-size: 1rem;
  font-weight: 400;
  display: table;
  padding: 11px 20px;
  cursor: pointer;
  transition: all .25s ease;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border: none;
  border-radius: 10px;
  outline: none;
  appearance: none; }
  .c-btn--primary {
    background-color: #792C3B; }
    .c-btn--primary:hover {
      background-color: #9e3a4d; }
  .c-btn--secondary {
    background-color: #898989; }
    .c-btn--secondary:hover {
      background-color: #a3a3a3; }

html {
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }

body {
  min-width: 320px;
  max-width: 100vw;
  overflow-x: hidden; }
  @media screen and (min-width: 551px) {
    body {
      max-height: 100vh;
      overflow: hidden; } }

a {
  transition: all .25s ease;
  text-decoration: none;
  color: inherit; }

.flex-header {
  width: 100%;
  margin-bottom: 20px; }
  @media screen and (min-width: 551px) {
    .flex-header {
      margin-bottom: 40px; } }
  @media screen and (min-width: 741px) {
    .flex-header {
      margin-bottom: 10vh; } }
  @media screen and (min-width: 1221px) {
    .flex-header {
      margin-bottom: 15vh; } }
  .flex-header__top {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
    @media screen and (min-width: 551px) {
      .flex-header__top {
        width: calc(100% / 12 * 11 - 75px);
        margin-left: auto;
        margin-right: 0; } }
    @media screen and (min-width: 741px) {
      .flex-header__top {
        position: relative;
        border-left: 2px solid #DEE2E8;
        border-bottom: 2px solid #DEE2E8; } }
    @media screen and (min-width: 951px) {
      .flex-header__top {
        width: calc(100% / 12 * 10 - 50px); } }
    .flex-header__top ul {
      width: 100%;
      padding: 25px 20px;
      background-color: #792C3B;
      color: #fff;
      font-weight: 600;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-size: 0; }
      @media screen and (min-width: 551px) {
        .flex-header__top ul {
          padding: 15px 20px;
          width: auto;
          float: right;
          display: block;
          font-size: 1rem; } }
      @media screen and (min-width: 951px) {
        .flex-header__top ul {
          padding: 20px 40px;
          font-size: 1.563rem; } }
      @media screen and (min-width: 1221px) {
        .flex-header__top ul {
          padding: 40px 60px; } }
      .flex-header__top ul li {
        position: relative;
        padding-left: 30px; }
        @media screen and (min-width: 951px) {
          .flex-header__top ul li {
            padding-left: 40px; } }
        .flex-header__top ul li svg {
          width: 18px;
          height: 18px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%); }
          @media screen and (min-width: 951px) {
            .flex-header__top ul li svg {
              width: 25px;
              height: 25px; } }
          .flex-header__top ul li svg * {
            fill: currentColor; }
        .flex-header__top ul li + li {
          margin-left: 20px; }
          @media screen and (min-width: 551px) {
            .flex-header__top ul li + li {
              margin-top: 10px;
              margin-left: 0; } }
          @media screen and (min-width: 951px) {
            .flex-header__top ul li + li {
              margin-top: 20px; } }
        .flex-header__top ul li a:hover {
          color: #000; }
    .flex-header__top:before {
      position: absolute;
      top: 100%;
      right: 0;
      width: 2px;
      height: 100vh;
      background-color: #DEE2E8;
      z-index: -1; }
      @media screen and (min-width: 741px) {
        .flex-header__top:before {
          content: ''; } }
    .flex-header__top:after {
      position: absolute;
      bottom: 0;
      left: 100%;
      width: 25px;
      height: 2px;
      background-color: #DEE2E8;
      z-index: 1; }
      @media screen and (min-width: 741px) {
        .flex-header__top:after {
          content: ''; } }
      @media screen and (min-width: 1221px) {
        .flex-header__top:after {
          width: calc((100vw - 1180px) / 2); } }
  .flex-header__bottom {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end; }
    @media screen and (min-width: 551px) {
      .flex-header__bottom {
        padding-top: 20px; } }
    @media screen and (min-width: 741px) {
      .flex-header__bottom {
        padding-top: 0; } }
    .flex-header__bottom ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      align-content: flex-start;
      color: #792C3B;
      font-weight: 700;
      padding-right: 20px;
      position: absolute;
      top: calc(100% + 20px);
      right: 0;
      width: calc(100% - 155px);
      font-size: 0.865rem; }
      @media screen and (min-width: 351px) {
        .flex-header__bottom ul {
          font-size: 1rem; } }
      @media screen and (min-width: 401px) {
        .flex-header__bottom ul {
          width: calc(100% - 215px); } }
      @media screen and (min-width: 551px) {
        .flex-header__bottom ul {
          text-align: right;
          position: relative;
          top: 0;
          flex-wrap: nowrap;
          justify-content: flex-end;
          width: auto; } }
      @media screen and (min-width: 951px) {
        .flex-header__bottom ul {
          font-size: 20px;
          padding-right: 10px; } }
      .flex-header__bottom ul li {
        width: 100%; }
        @media screen and (min-width: 551px) {
          .flex-header__bottom ul li {
            width: auto; } }
        .flex-header__bottom ul li + li {
          margin-top: 5px; }
          @media screen and (min-width: 551px) {
            .flex-header__bottom ul li + li {
              margin-top: 0; } }
          @media screen and (min-width: 551px) {
            .flex-header__bottom ul li + li:before {
              content: '|';
              padding: 0 5px; } }
          @media screen and (min-width: 951px) {
            .flex-header__bottom ul li + li:before {
              padding: 0 10px; } }
          @media screen and (min-width: 1221px) {
            .flex-header__bottom ul li + li:before {
              padding: 0 30px; } }

.logo-header {
  position: absolute;
  top: 10px;
  left: 20px; }
  @media screen and (min-width: 551px) {
    .logo-header {
      top: 15px;
      max-height: 50px;
      background-color: #fff; } }
  @media screen and (min-width: 741px) {
    .logo-header {
      position: relative;
      top: 0;
      left: 0;
      margin-top: -10px; } }
  @media screen and (min-width: 951px) {
    .logo-header {
      margin-top: -14px;
      max-height: 70px; } }
  @media screen and (min-width: 1221px) {
    .logo-header {
      max-height: 80px;
      margin-top: -16px; } }
  .logo-header:before {
    position: absolute;
    top: 100%;
    right: 64px;
    width: 2px;
    height: 10vh;
    background-color: #DEE2E8;
    z-index: -1; }
    @media screen and (min-width: 741px) {
      .logo-header:before {
        content: ''; } }
    @media screen and (min-width: 951px) {
      .logo-header:before {
        right: 84px; } }
    @media screen and (min-width: 1221px) {
      .logo-header:before {
        right: 104px;
        height: 15vh; } }
  .logo-header:after {
    position: absolute;
    bottom: 0;
    right: 75px;
    width: 100%;
    height: 2px;
    background-color: #DEE2E8;
    z-index: 1; }
    @media screen and (min-width: 741px) {
      .logo-header:after {
        content: ''; } }
    @media screen and (min-width: 951px) {
      .logo-header:after {
        right: 100px; } }
    @media screen and (min-width: 1221px) {
      .logo-header:after {
        width: calc((100vw - 1220px) / 2 + 205px);
        right: 121px; } }
  .logo-header svg {
    width: 120px;
    height: 32px; }
    @media screen and (min-width: 551px) {
      .logo-header svg {
        width: 190px;
        height: 50px; } }
    @media screen and (min-width: 951px) {
      .logo-header svg {
        width: 250px;
        height: 70px; } }
    @media screen and (min-width: 1221px) {
      .logo-header svg {
        width: 305px;
        height: 80px; } }
    @media screen and (max-width: 550px) {
      .logo-header svg * {
        fill: #fff; } }

/* article default styling */
h1 {
  font-size: 2em;
  line-height: 1.25; }
  @media screen and (min-width: 771px) {
    h1 {
      font-size: 2.5em;
      line-height: 1.125; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 3em;
      line-height: 1.05; } }

h2 {
  font-size: 1.625em;
  line-height: 1.15; }
  @media screen and (min-width: 771px) {
    h2 {
      font-size: 2em;
      line-height: 1.25; } }
  @media screen and (min-width: 1024px) {
    h2 {
      font-size: 2.25em; } }

h3 {
  font-size: 1.375em;
  line-height: 1.136; }
  @media screen and (min-width: 771px) {
    h3 {
      font-size: 1.5em;
      line-height: 1.25; } }
  @media screen and (min-width: 1024px) {
    h3 {
      font-size: 1.75em; } }

h4 {
  font-size: 1.125em;
  line-height: 1.11; }
  @media screen and (min-width: 771px) {
    h4 {
      font-size: 1.125em;
      line-height: 1.122; } }

h5 {
  font-size: 1em; }

h6 {
  font-size: .85em; }

article p,
article ol,
article ul {
  font-size: 1em;
  display: block;
  margin: 0 0 1.25em 0; }
  article p a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary),
  article ol a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary),
  article ul a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary) {
    color: #792C3B; }
    article p a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary):hover,
    article ol a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary):hover,
    article ul a:not(.c-btn):not(.c-btn--primary):not(.c-btn--secondary):hover {
      color: #9e3a4d; }

article ul {
  padding-left: 20px;
  list-style: disc; }

article ol {
  padding-left: 20px;
  list-style: decimal; }

article b,
article strong {
  font-weight: bold; }

article i,
article em {
  font-style: italic; }

article u {
  text-decoration: underline; }

article .c-btn, article .c-btn--primary, article .c-btn--secondary {
  margin: 0 0 1em 0;
  line-height: 1; }

article q,
article blockquote {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  margin: 0 0 1em 0;
  font-size: 1.25em;
  line-height: 1.25; }
  @media screen and (min-width: 771px) {
    article q,
    article blockquote {
      font-size: 1.5em;
      line-height: 1.45; } }
  article q p,
  article blockquote p {
    margin-bottom: 0; }
    article q p:before,
    article blockquote p:before {
      content: '\201d'; }
    article q p:after,
    article blockquote p:after {
      content: '\201c'; }
    article q p + p,
    article blockquote p + p {
      text-align: right;
      font-weight: bold;
      display: block;
      font-size: 1em; }
      @media screen and (min-width: 771px) {
        article q p + p,
        article blockquote p + p {
          font-size: 1.25em; } }
      article q p + p:before, article q p + p:after,
      article blockquote p + p:before,
      article blockquote p + p:after {
        content: none; }

article h1 {
  font-weight: bold;
  display: block;
  margin: 0 0 .67em 0; }

article h2 {
  font-weight: bold;
  display: block;
  margin: 0 0 .83em 0; }

article h3 {
  font-weight: bold;
  display: block;
  margin: 0 0 1em 0; }

article h4 {
  font-weight: bold;
  display: block;
  margin: 0 0 1.33em 0; }

article h5 {
  font-weight: bold;
  display: block;
  margin: 0 0 1.37em 0; }

article h6 {
  font-weight: bold;
  display: block;
  margin: 0 0 1.75em 0; }

article *:last-child {
  margin-bottom: 0; }

article mark {
  background-color: #9e3a4d; }

[class*="row-text"] + [class*="row-text"] {
  padding-top: 0; }

@media screen and (min-width: 551px) {
  .row-landing .u-content-block {
    align-items: flex-end;
    align-content: flex-end; } }

.row-landing__img {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  position: relative; }
  @media screen and (min-width: 551px) {
    .row-landing__img {
      width: calc(100% / 12 * 4 - 10px);
      flex-direction: column; } }
  @media screen and (min-width: 741px) {
    .row-landing__img {
      width: calc(100% / 12 * 3 - 10px); } }
  .row-landing__img .u-img-container {
    width: 100%;
    height: 130px;
    max-width: 120px; }
    @media screen and (min-width: 401px) {
      .row-landing__img .u-img-container {
        height: 230px;
        max-width: 230px; } }
    @media screen and (min-width: 551px) {
      .row-landing__img .u-img-container {
        margin-bottom: 10px; } }
    .row-landing__img .u-img-container:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 2px;
      height: 50vh;
      background-color: #DEE2E8;
      z-index: -1; }
      @media screen and (min-width: 741px) {
        .row-landing__img .u-img-container:before {
          content: ''; } }
    .row-landing__img .u-img-container:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(222, 226, 232, 0.5); }
  .row-landing__img ul {
    color: #898989;
    font-weight: 700;
    max-width: 230px;
    width: 100%;
    padding-left: 15px; }
    @media screen and (min-width: 551px) {
      .row-landing__img ul {
        min-height: 40px; } }
    @media screen and (max-width: 740px) and (min-width: 551px) {
      .row-landing__img ul {
        padding-left: 0; } }
    .row-landing__img ul li:last-child {
      font-size: 12px; }

.row-landing article {
  width: 100%;
  font-weight: 500;
  padding-top: 20px; }
  @media screen and (min-width: 551px) {
    .row-landing article {
      width: calc(100% / 12 * 8 - 10px);
      padding-bottom: 50px;
      padding-top: 0; } }
  @media screen and (min-width: 741px) {
    .row-landing article {
      margin: 0 auto; } }
